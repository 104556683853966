.LexicalEditorTheme__ltr {
  text-align: left;
}
.LexicalEditorTheme__rtl {
  text-align: right;
}
.LexicalEditorTheme__paragraph {
  margin: 0;
  position: relative;
}
.LexicalEditorTheme__quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.LexicalEditorTheme__h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
}
.LexicalEditorTheme__h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.LexicalEditorTheme__h3 {
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}
.LexicalEditorTheme__indent {
  --lexical-indent-base-value: 40px;
}
.LexicalEditorTheme__textBold {
  font-weight: bold;
}
.LexicalEditorTheme__textItalic {
  font-style: italic;
}
.LexicalEditorTheme__textUnderline {
  text-decoration: underline;
}
.LexicalEditorTheme__textStrikethrough {
  text-decoration: line-through;
}
.LexicalEditorTheme__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.LexicalEditorTheme__textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.LexicalEditorTheme__textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}
.LexicalEditorTheme__textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}
.LexicalEditorTheme__hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.LexicalEditorTheme__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.LexicalEditorTheme__link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.LexicalEditorTheme__code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
}
.LexicalEditorTheme__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.LexicalEditorTheme__tableScrollableWrapper {
  overflow-x: auto;
  margin: 0px 25px 30px 0px;
}
.LexicalEditorTheme__tableScrollableWrapper > .LexicalEditorTheme__table {
  /* Remove the table's margin and put it on the wrapper */
  margin: 0;
}
.LexicalEditorTheme__table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: fit-content;
  margin: 0px 25px 30px 0px;
}
.LexicalEditorTheme__tableRowStriping tr:nth-child(even) {
  background-color: #f2f5fb;
}
.LexicalEditorTheme__tableSelection *::selection {
  background-color: transparent;
}
.LexicalEditorTheme__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.LexicalEditorTheme__tableCell {
  border: 1px solid #bbb;
  width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.LexicalEditorTheme__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.LexicalEditorTheme__tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.LexicalEditorTheme__tableCellSelected {
  caret-color: transparent;
}
.LexicalEditorTheme__tableCellSelected::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: highlight;
  mix-blend-mode: multiply;
  content: "";
  pointer-events: none;
}
.LexicalEditorTheme__tableAddColumns {
  position: absolute;
  background-color: #eee;
  height: 100%;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.LexicalEditorTheme__tableAddColumns:after {
  background-image: url(../icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.LexicalEditorTheme__tableAddColumns:hover,
.LexicalEditorTheme__tableAddRows:hover {
  background-color: #c9dbf0;
}
.LexicalEditorTheme__tableAddRows {
  position: absolute;
  width: calc(100% - 25px);
  background-color: #eee;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.LexicalEditorTheme__tableAddRows:after {
  background-image: url(../icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.LexicalEditorTheme__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.LexicalEditorTheme__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.LexicalEditorTheme__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.LexicalEditorTheme__tableCellActionButton:hover {
  background-color: #ddd;
}
.LexicalEditorTheme__characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.LexicalEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.LexicalEditorTheme__ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: outside;
}
.LexicalEditorTheme__ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: outside;
}
.LexicalEditorTheme__ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: outside;
}
.LexicalEditorTheme__ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: outside;
}
.LexicalEditorTheme__ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.LexicalEditorTheme__listItem {
  margin: 0 32px;
}
.LexicalEditorTheme__listItemChecked,
.LexicalEditorTheme__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.LexicalEditorTheme__listItemChecked {
  text-decoration: line-through;
}
.LexicalEditorTheme__listItemUnchecked:before,
.LexicalEditorTheme__listItemChecked:before {
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.LexicalEditorTheme__listItemUnchecked[dir="rtl"]:before,
.LexicalEditorTheme__listItemChecked[dir="rtl"]:before {
  left: auto;
  right: 0;
}
.LexicalEditorTheme__listItemUnchecked:focus:before,
.LexicalEditorTheme__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.LexicalEditorTheme__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.LexicalEditorTheme__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.LexicalEditorTheme__listItemChecked:after {
  content: "";
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.LexicalEditorTheme__nestedListItem {
  list-style-type: none;
}
.LexicalEditorTheme__nestedListItem:before,
.LexicalEditorTheme__nestedListItem:after {
  display: none;
}
.LexicalEditorTheme__tokenComment {
  color: slategray;
}
.LexicalEditorTheme__tokenPunctuation {
  color: #999;
}
.LexicalEditorTheme__tokenProperty {
  color: #905;
}
.LexicalEditorTheme__tokenSelector {
  color: #690;
}
.LexicalEditorTheme__tokenOperator {
  color: #9a6e3a;
}
.LexicalEditorTheme__tokenAttr {
  color: #07a;
}
.LexicalEditorTheme__tokenVariable {
  color: #e90;
}
.LexicalEditorTheme__tokenFunction {
  color: #dd4a68;
}
.LexicalEditorTheme__mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.LexicalEditorTheme__markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.LexicalEditorTheme__mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.LexicalEditorTheme__markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.LexicalEditorTheme__embedBlock {
  user-select: none;
}
.LexicalEditorTheme__embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
.LexicalEditorTheme__layoutContainer {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}
.LexicalEditorTheme__layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}
.LexicalEditorTheme__autocomplete {
  color: #ccc;
}
.LexicalEditorTheme__hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}
.LexicalEditorTheme__hr:after {
  content: "";
  display: block;
  height: 2px;
  background-color: #ccc;
  line-height: 2px;
}
.LexicalEditorTheme__hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.LexicalEditorTheme__specialText {
  background-color: yellow;
  font-weight: bold;
}

.editor {
  flex: auto;
  max-width: 100%;
  position: relative;
  resize: vertical;
  z-index: -1;
}

.editor-scroller {
  min-height: 150px;
  max-width: 100%;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  resize: vertical;
}

span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-image img.focused.draggable {
  cursor: grab;
}

.editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: fixed;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
