.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
}
